@import "bootstrap";
@import "font-awesome";

@import "variables";

// @font-face {
//   font-family: "Octin Spraypaint W00 A Regular";
//   src: url("https://fast.fonts.net/dv2/ot/3/3b1e09e6-ff31-474e-8b36-76884a54ae08.woff?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&projectid=74f39a9d-a5dc-405f-9690-1c1fd4590ae4");
// }

@font-face {
  font-family: "Octin Spraypaint W00 A Regular";
  src: url("//db.onlinewebfonts.com/t/17ac861874f9de206f449e1735f35c54.eot");
  src: url("//db.onlinewebfonts.com/t/17ac861874f9de206f449e1735f35c54.eot?#iefix") format("embedded-opentype"),
  url("/assets/fonts/octinspray.woff2") format("woff2"),
  url("//db.onlinewebfonts.com/t/17ac861874f9de206f449e1735f35c54.woff") format("woff"),
  url("//db.onlinewebfonts.com/t/17ac861874f9de206f449e1735f35c54.ttf") format("truetype"),
  url("//db.onlinewebfonts.com/t/17ac861874f9de206f449e1735f35c54.svg#OctinSpraypaintW00-ARg") format("svg");
}
body.berlin {
  background-image: url('../img/concretewall.jpg');
  background-position: center -50px;
  background-size: cover; // 100% auto;
  // background-attachment: fixed;

  .global-notification {
    border: none;
    border-radius: 0;
    font-size: @font-size-small;
    line-height: 1;
    padding: 6px;
    text-align: center;

    &.alert-danger {
      color: #e4282a;
    }
  }

  .row {
    margin: 0;

    img {
      width: 100%;
    }
  }

  .fsel {
    height: 92vh;
    width: 100%;
    text-align: center;
  }

  .wrapper {
    position: relative;
  }

  .center {
    text-align: center;
  }

  .headlogo {
    height: 60vmin;
    margin: 8vh auto 0;
    display: block;
  }

  .headlogo-text {
    margin-top: -30px;
    font-size: 5vmin;
  }
  .headtitle {
    font-size: @font-size-base * 3.5;
    height: 100px;
    margin-top: 100px;
  }
  .sprayed-box {
    box-shadow: -1px -1px 5px 2px #111, 0 5px 8px 1px #111, -2px -1px 8px 5px #eee;
    padding: 10px 32px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: rgba(0, 0, 0, 0.7);
    text-shadow: -1px -1px 5px #111, 0 5px 8px #111, -2px -1px 8px #eee;
    font-weight: 400;
    transform: skewY(-16deg);
    text-transform: uppercase;
    font-family: 'Octin Spraypaint W00 A Regular', @font-family-base;
    &:focus {
      outline: none;
    }
  }

  .sprayed-text {
    color: rgba(0, 0, 0, 0.7);
    text-shadow: -1px -1px 5px #111, 0 5px 8px #111, -2px -1px 8px #eee;
    font-weight: 400;
    transform: skewY(-16deg);
    text-transform: uppercase;
    font-family: 'Octin Spraypaint W00 A Regular', @font-family-base;
  }

  .spacer-30 {
    margin-bottom: 30px;
  }

  .soldout {
    // font-size: 150%;
    font-size: 4vmin;
    color: @brand-primary;
    text-shadow: -1px -1px 5px #111, 0 5px 8px @brand-primary, -2px -1px 8px #111;
  }
  .painted {
    margin-top: 84px;
    margin-bottom: 84px;
    &:after, &:before {
      height: 58px;
      width: 100%;
      left: 0;
      right: 0;
      position: absolute;
      content: "";
      display: block;
    }
  }

  .painted-white {
    background-color: #fff;
    &:after {
      background: url('../img/box-after.png') center 0 repeat-x;
      top: -58px;
    }
    &:before {
      background: url('../img/box-before.png') center 0 repeat-x;
      bottom: -58px;
    }
  }

  .footer {

    background: url('../img/bg-footer.jpg') center 0;
    margin-top: 40px;
    color: @gray-lighter;

    &:before {
      height: 40px;
      width: 100%;
      left: 0;
      right: 0;
      position: relative;
      content: "";
      display: block;
      background: url('../img/footer-after.png') center 0 repeat-x;
      top: -40px;
    }
  }
  ul.footer-menu {
    list-style-type: none;
    padding-left: 0;
    a {
      color: @brand-primary;
      &:hover, &:focus {
        color: @body-bg;
        text-decoration: none;
      }
    }
  }

  .important {
    border: 2px solid red;
    border-radius: 5px;
    padding: 10px;
    ul {
      margin: 0;
    }
  }
}

.formal {

  .tab-content {
    padding-top: 3rem;
    border-bottom: @nav-tabs-border-color solid 1px;
    margin-bottom: 3rem;
  }
  .flex-container {
    display: flex;
    flex-flow: row wrap;
    box-sizing: border-box;
    margin: 50px 0 50px;
  }
  .flex-item {
    padding: 2rem 15px;
    box-sizing: border-box;
    width: 100%;
  }
  .panel {
    border-radius: 0;
    border: none;
    box-sizing: border-box;
    box-shadow: -1px -1px 5px 2px rgba(17,17,17,0.5), 0 5px 8px 1px rgba(17,17,17,0.5), -2px -1px 8px 5px  fade(@brand-primary,50);
    // color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
    transform: skewY(-16deg);
    // font-family: 'Octin Spraypaint W00 A Regular', @font-family-base;
  }
  .panel-heading {
    border-radius: 0;
    background-color: @brand-primary;
    color: @body-bg;
    box-shadow: -1px -1px 5px 2px rgba(17,17,17,0.5), 0 5px 8px 1px rgba(17,17,17,0.5), -2px -1px 8px 5px  fade(@brand-primary,50);
    text-shadow: -1px -1px 5px #111, 0 5px 8px #111, -2px -1px 8px @body-bg;
    text-align: center;
    text-transform: uppercase;
  }
  .panel-body {
    border: 1px solid @brand-primary;
  }
  ul.prices{
    list-style: none;
    display: block;
    text-align: center;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      text-align: center;
      margin-right: 0.75rem;
    }
    .past {
      text-decoration: line-through;
      color: @gray-light;
    }
    .present {
      text-decoration: none;
    }
    .future {
      text-decoration: none;
      color: @gray-light;
    }
  }
  .smallprint { font-size: @font-size-small/1.5; }
  .itemlist {
    margin: 0;
    list-style-type: none;
    td {
      border-top: none;
      padding: 0 5px 0 25px;
    }
  }
  .footborder {
    border-top: 2px solid #ddd;
  }
  .noborder {
    border: none !important;
  }
}
